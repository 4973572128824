import React, { useEffect, useMemo, useState } from "react";
import { Button, Dropdown, FormField, FormGroup, Input, Modal, TextArea } from "semantic-ui-react";
// UI Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import SnackMessage from "react-lib/apps/common/SnackMessage";
// UI ANE
import CardAnesRecord01UX from "./CardAnesRecord01UX";
import CardAnesRecord02UX from "./CardAnesRecord02UX";
import CardANSLog from "react-lib/apps/ANS/CardANSLog";
import moment from "moment";
import SubAssistantsInput from "../ORM/SubAssistantsInput";
import { useIntl } from "react-intl";
import SearchBoxWithKey from "react-lib/appcon/common/SearchBoxWithKey";
//

const CARD_KEY: string = "CardAnestheticAssessmentRecord";
const FORM_CODE: string = "CardAnestheticAssessmentRecord";
const FORM_NAME: string = "CardAnestheticAssessmentRecord";
const FORM_VERSION: string = "1.0";

type CardAnesRecordProps = {
  // function
  onEvent: any;
  setProp: any;

  // CommonInterface
  errorMessage?: any;
  successMessage?: any;
  buttonLoadCheck?: any;

  // options
  masterOptions?: any;

  // seq
  isPHV?: boolean;
  runSequence?: any;
  AnesRecordSequence?: any;
  selectedOrOrder?: any;
  searchedItemListWithKey?: any;
};

const CardAnesRecord = (props: CardAnesRecordProps) => {
  const intl = useIntl();
  const { IsGeneralAnesthesia, IsRegionalAnesthesia, IsMAC, IsCombined } =
    props.AnesRecordSequence?.data || {};

  // Selected Anesthesiologist
  const selectedAnesTeam = useMemo(() => {
    const anesTeamValue = props.AnesRecordSequence?.data?.Anesthesiologist;
    return props.masterOptions?.doctor?.find((item: any) => item.value === anesTeamValue) || {};
  }, [props.masterOptions, props.AnesRecordSequence?.data?.Anesthesiologist]);

  useEffect(() => {
    props.runSequence({
      sequence: "AnesRecord",
      action: "FETCH_ANESRECORD",
      cardKey: CARD_KEY,
      formCode: FORM_CODE,
      formVersion: FORM_VERSION,
      restart: true,
    });
  }, []);

  useEffect(() => {
    if (props.selectedOrOrder) {
      props.runSequence({
        sequence: "AnesRecord",
        action: "FETCH_ANESRECORD",
        cardKey: CARD_KEY,
        formCode: FORM_CODE,
        formVersion: FORM_VERSION,
        restart: true,
      });
    }
  }, [props.selectedOrOrder]);

  useEffect(() => {
    if (props.AnesRecordSequence?.data?.Anesthesiologist) {
      const { text } = selectedAnesTeam;
      props.setProp(`AnesRecordSequence.data.AnesthesiaTeam`, text);
    }
  }, [props.AnesRecordSequence?.data?.Anesthesiologist, selectedAnesTeam]);

  const handleChangeData = (_event: any, data: any) => {
    if (data.type === "checkbox") {
      // Anesthetic Technique
      if (data.name === "IsGeneralAnesthesia" && !data.checked) {
        props.setProp(`AnesRecordSequence.data`, {
          ...props.AnesRecordSequence?.data,
          IsGeneralAnesthesia: false,
          IsBalanced: false,
          IsInhalation: false,
          IsTIVA: false,
          IsNitrousOxide: false,
          IsGeneralAnesthesiaOthers: false,
          IsGeneralAnesthesiaOthersRemark: "",
        });
      } else if (data.name === "IsRegionalAnesthesia" && !data.checked) {
        props.setProp(`AnesRecordSequence.data`, {
          ...props.AnesRecordSequence?.data,
          IsRegionalAnesthesia: false,
          IsSpinalBlock: false,
          IsEpiduralBlock: false,
          IsCombinedSpinalAndEpiduralBlock: false,
          IsCaudalblock: false,
          IsBrachialPlexusBlock: false,
          IsRegionalAnesthesiaOthers: false,
          IsRegionalAnesthesiaOthersRemark: "",
        });
      } else if (
        ["IsGeneralAnesthesiaOthers", "IsRegionalAnesthesiaOthers", "IsMAC", "IsCombined"].includes(
          data.name
        ) &&
        !data.checked
      ) {
        props.setProp(`AnesRecordSequence.data`, {
          ...props.AnesRecordSequence?.data,
          [`${data.name}`]: false,
          [`${data.name}Remark`]: "",
        });
      } else if (data.name === "AirwayManagementIsNone" && data.checked) {
        props.setProp(`AnesRecordSequence.data`, {
          ...props.AnesRecordSequence?.data,
          [`${data.name}`]: data.checked,
          AirwayManagementAreaDropdown2: "",
          AirwayManagementAreaNo2: "",
          AirwayManagementAreaDropdown3: "",
          AirwayManagementAreaNo3: "",
          AirwayManagementAreaDropdown4: "",
          AirwayManagementAreaNo4: "",
          AirwayManagementAreaDropdown5: "",
        });
      } else {
        props.setProp(`AnesRecordSequence.data.${data.name}`, data.checked);
      }
    } else {
      props.setProp(`AnesRecordSequence.data.${data.name}`, data.value);
    }
  };

  const handleNurseSearchChange = (_e: any, data: any) => {
    if (data.searchQuery.length > 2) {
      props.runSequence({
        sequence: "AnesRecord",
        action: "SEARCH_NURSE",
        searchQuery: data.searchQuery,
      });
    }
  };

  const handleSetTime = () => {
    let dataRecord = props.AnesRecordSequence?.data;
    let newEndDate = moment(
      `${dataRecord?.EndAnesDate} ${dataRecord?.EndAnesTime}`,
      "DD/MM/YYYY HH:mm"
    );
    let newStartDate = moment(
      `${dataRecord?.StartAnesDate} ${dataRecord?.StartAnesTime}`,
      "DD/MM/YYYY HH:mm"
    );

    let totalTime = newEndDate.diff(newStartDate, "minutes");

    props.setProp("AnesRecordSequence.data", {
      ...props.AnesRecordSequence?.data,
      TotalAnseTimeHour: Math.floor(Number(totalTime) / 60) || 0,
      TotalAnseTimeMinute: Number(totalTime) % 60 || 0,
    });
  };

  // -------------------- Modal Log
  const handleCloseModLog = () => {
    props.setProp("AnesRecordSequence.modLogInfo", {
      open: false,
      logList: [],
    });
  };

  const handleUpdateScrubNurse = (index: number) => (items: any[]) => {
    props.setProp(`AnesRecordSequence.data.AnesthesiaNurse`, items);
  };

  const handleAnesthesiologist = (value: any) => {
    handleChangeData(null, { name: "Anesthesiologist", value });
  };

  return (
    <>
      <SnackMessage
        onEvent={props.onEvent}
        onClose={() => {
          props.setProp(`errorMessage.${CARD_KEY}`, null);
          props.setProp(`successMessage.${CARD_KEY}`, null);
        }}
        error={props.errorMessage?.[CARD_KEY]}
        success={props.successMessage?.[CARD_KEY]}
        languageUX={props.languageUX}
      />

      <CardAnesRecord01UX
        isPHV={props.isPHV}
        AnesRecordSequence={props.AnesRecordSequence?.data}
        AnesRecordStatus={props.AnesRecordSequence?.status}
        masterOptions={props.masterOptions}
        userNurseOptions={props.AnesRecordSequence?.userNurseOptions}
        onChangeData={handleChangeData}
        onNurseSearchChange={handleNurseSearchChange}
        onChangeStartDate={(date: any) => {
          props.setProp("AnesRecordSequence.data.StartAnesDate", date);
          handleSetTime();
        }}
        onChangeEndDate={(date: any) => {
          props.setProp("AnesRecordSequence.data.EndAnesDate", date);
          handleSetTime();
        }}
        onChangeDateForm={(date: string) =>
          props.setProp("AnesRecordSequence.data.DateRamrk", date)
        }
        onChangeStartAnesTime={(value: string) => {
          props.setProp("AnesRecordSequence.data.StartAnesTime", value);
          handleSetTime();
        }}
        onChangeStartOpTime={(value: string) =>
          props.setProp("AnesRecordSequence.data.StartOpTime", value)
        }
        onChangeEndAnesTime={(value: string) => {
          props.setProp("AnesRecordSequence.data.EndAnesTime", value);
          handleSetTime();
        }}
        // component
        buttonLog={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "AnesRecord",
                action: "LOG",
                cardKey: CARD_KEY,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_LOG`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_LOG`]}
            // config
            color="orange"
            size="medium"
            style={{ width: "120px" }}
            title="Log"
          />
        }
        buttonPrint={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "AnesRecord",
                action: "PRINT",
                cardKey: CARD_KEY,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_PRINT`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_PRINT`]}
            // config
            color="blue"
            size="medium"
            style={{ width: "120px", ...(props.isPHV && { display: "none" }) }}
            title="Print"
          />
        }
        buttonSave={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "AnesRecord",
                action: "SAVE",
                cardKey: CARD_KEY,
                formCode: FORM_CODE,
                formName: FORM_NAME,
                formVersion: FORM_VERSION,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_SAVE`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_SAVE`]}
            // config
            color={"orange"}
            size="medium"
            style={{ width: "120px", ...(props.isPHV && { display: "none" }) }}
            title={"Save Draft"}
          />
        }
        buttonConfirm={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={() => {
              props.runSequence({
                sequence: "AnesRecord",
                action: "CONFIRM",
                cardKey: CARD_KEY,
                formCode: FORM_CODE,
                formName: FORM_NAME,
                formVersion: FORM_VERSION,
              });
            }}
            // data
            paramKey={`${CARD_KEY}_CONFIRM`}
            buttonLoadCheck={props.buttonLoadCheck?.[`${CARD_KEY}_CONFIRM`]}
            // config
            color="green"
            size="medium"
            style={{ width: "120px", ...(props.isPHV && { display: "none" }) }}
            title="Confirm"
          />
        }
        SurgeonElement={
          <SearchBoxWithKey
            // callback
            onEvent={props.onEvent}
            type="Doctor"
            id="Anesthesiologist_1"
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={props.AnesRecordSequence?.data?.Anesthesiologist || null}
            setSelected={handleAnesthesiologist}
          />
        }
        AnesthetistElement={
          <SubAssistantsInput
            // callback
            onEvent={props.onEvent}
            // data
            index={0}
            items={props.AnesRecordSequence?.data?.AnesthesiaNurse || []}
            type="User"
            role="REGISTERED_NURSE"
            field="Anesthetist"
            hideDateTime={true}
            searchedItemListWithKey={props.searchedItemListWithKey}
            onUpdated={handleUpdateScrubNurse(0)}
            languageUX={props.languageUX}
          />
        }
        languageUX={props.languageUX}
      />

      <div style={{ ...(props.isPHV && { pointerEvents: "none" }) }}>
        <CardAnesRecord02UX
          AnesRecordSequence={props.AnesRecordSequence?.data}
          readOnlyGAMAC={
            (!IsGeneralAnesthesia && !IsRegionalAnesthesia && !IsMAC && !IsCombined) ||
            (IsGeneralAnesthesia && !IsRegionalAnesthesia && !IsMAC && !IsCombined) ||
            (!IsCombined && !IsRegionalAnesthesia && IsMAC)
          }
          masterOptions={props.masterOptions}
          onChangeData={handleChangeData}
          premedoication={
            <PremedoicationData
              AnesRecordSequence={props.AnesRecordSequence}
              masterOptions={props.masterOptions}
              setProp={props.setProp}
            />
          }
          languageUX={props.languageUX}
        />
      </div>

      <Modal
        open={props.AnesRecordSequence?.modLogInfo?.open}
        closeOnDimmerClick={true}
        onClose={handleCloseModLog}
      >
        <CardANSLog
          showAction={true}
          editDetail={true}
          dataList={props.AnesRecordSequence?.modLogInfo?.logList || []}
          hideCallback={handleCloseModLog}
          languageUX={props.languageUX}
        />
      </Modal>
    </>
  );
};

const PremedoicationData = (props: any) => {
  const handleChangePremedication = (data: any, index: number) => {
    props.setProp(`AnesRecordSequence.data.Premedication.${index}.premedication`, data.value);
  };

  const handlerAddPremedication = () => {
    const Premedication = { premedication: "" };
    const items: any[] = props.AnesRecordSequence?.data?.Premedication || [];

    items.push(Premedication);
    props.setProp("AnesRecordSequence.data.Premedication", items);
  };

  const handlerDeletePremedication = (index: number) => {
    const items: any[] = props.AnesRecordSequence?.data?.Premedication || [];

    if (items?.length === 1) {
      props.setProp("AnesRecordSequence.data.Premedication.0", {
        ...(props.AnesRecordSequence?.data?.Premedication || []),
      });
    } else if (items.length > 1) {
      items.splice(index, 1);
      props.setProp("AnesRecordSequence.data.Premedication", items);
    }
  };

  const premedicationOption = useMemo(() => {
    let premedication = (props.AnesRecordSequence?.data?.Premedication || []).map((item: any) => {
      return { text: item?.premedication, value: item?.premedication, key: item?.premedication };
    });

    return [...(props.masterOptions?.drugName || []), ...premedication];
  }, [props.AnesRecordSequence?.data?.Premedication, props.masterOptions?.drugName]);

  const handleAddition = (e, { value }) => {
    props.setProp("masterOptions.drugName", [
      { text: value, value: value, key: value },
      ...props.masterOptions?.drugName,
    ]);
  };

  return (
    <>
      {(
        props.AnesRecordSequence?.data?.Premedication || [
          {
            premedication: "",
          },
        ]
      )?.map((item: any, index: any) => {
        return (
          <FormGroup inline={true}>
            <FormField inline={true} style={{ minWidth: "140px", maxWidth: "140px" }}>
              {index === 0 && (
                <label
                  style={{
                    fontWeight: "bold",
                    marginLeft: "5px",
                    display: "flex",
                    height: "40px",
                    fontSize: "1rem",
                    alignItems: "center",
                  }}
                >
                  Premedication
                </label>
              )}
            </FormField>
            <FormField inline={true} width={13}>
              <Dropdown
                clearable={true}
                onChange={(e: any, data: any) => handleChangePremedication(data, index)}
                options={premedicationOption}
                selection={true}
                search={true}
                allowAdditions={true}
                value={item?.premedication}
                onAddItem={handleAddition}
                style={{ width: "55%" }}
              ></Dropdown>
              <Button
                color="green"
                icon="plus"
                size="mini"
                style={{ marginRight: "10px" }}
                onClick={() => handlerAddPremedication()}
              ></Button>
              <Button
                color="red"
                icon="minus"
                size="mini"
                onClick={() => handlerDeletePremedication(index)}
              ></Button>
            </FormField>
          </FormGroup>
        );
      })}
    </>
  );
};

export default React.memo(CardAnesRecord);
